import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogProps } from '@material-ui/core';

import { DIALOG_SIZE } from '@utils/constants/dialog';
import { Header, ContentContainer, DialogHeader } from './styles';

export interface DialogDefaultProps extends DialogProps {
  onClickCloseButton?(): void;
  open: boolean;
  maxWidth: DIALOG_SIZE;
}

export const DialogDefault: React.FC<DialogDefaultProps> = ({
  children,
  onClickCloseButton,
  open,
  maxWidth,
  ...rest
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      {...rest}
      PaperProps={{
        style: { borderRadius: 13, margin: 20 },
      }}
    >
      <Header>
        <DialogHeader>
          <CloseIcon
            onClick={onClickCloseButton}
            data-testid="close-complete-register-onboarding"
          />
        </DialogHeader>
      </Header>
      <ContentContainer>{children}</ContentContainer>
    </Dialog>
  );
};
