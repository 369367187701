import {
  DialogTitle,
  styled,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

export const Header: any = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '5px 15px 0px 15px',
  [theme.breakpoints.down('sm')]: {
    padding: '5px 5px 0px 24px',
  },
}));

export const DialogHeader: any = styled(DialogActions)(({ theme }) => ({
  cursor: 'pointer',
  '& svg': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },
}));

export const ContentContainer: any = styled(DialogContent)({
  width: '100%',
  padding: '0 24px 35px 24px',
});

export const StartSeIcon = styled('img')({});
