import { PrimaryButton } from '@startse/design-system';
import { DIALOG_SIZE } from '@utils/constants/dialog';
import { useTranslation } from 'next-i18next';
import { DialogDefault } from '@components/core/DialogDefault';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useTracking } from '@hooks/tracking';
import {
  CenterContainer,
  CourseAlmostStarting,
  CourseName,
  NeedToConfirmContainer,
  NeedToConfirm,
  ButtonContainer,
  CalendarContainer,
  ShallWe,
} from './styles';
import CalendarIcon from '../../../assets/svg/calendar.svg';

interface IPopupPresence {
  open: boolean;
  onClickCloseButton?(): void;
  courseName?: string;
}

export const PopupPresence: React.FC<IPopupPresence> = ({
  open,
  onClickCloseButton,
  courseName,
}) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { locale, replace } = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const url = process.env.NEXT_PUBLIC_APP;
  const { track } = useTracking();

  function startCheckin() {
    track('Begin check-in', { CourseName: courseName });
    replace(
      `${process.env.NEXT_PUBLIC_CHECKIN_URL}/${
        locale || 'pt-BR'
      }?redirect_uri=${url}`,
    );
  }

  return (
    <DialogDefault
      fullWidth
      open={open}
      onClickCloseButton={() => {
        onClickCloseButton();
        track('Close check-in', { courseName });
      }}
      maxWidth={DIALOG_SIZE.SM}
    >
      <CenterContainer>
        <CalendarContainer>
          <CalendarIcon />
        </CalendarContainer>
        <CourseAlmostStarting>
          {t('checkin.course_almost_starting')}
        </CourseAlmostStarting>
        <CourseName>{courseName}</CourseName>
        <NeedToConfirmContainer>
          {isMobile ? (
            <>
              <NeedToConfirm>{t('checkin.just_need_confirm')}</NeedToConfirm>
              <ShallWe>{t('checkin.shall_we')}</ShallWe>
            </>
          ) : (
            <NeedToConfirm>
              {t('checkin.need_confirm_attendance')}
            </NeedToConfirm>
          )}
        </NeedToConfirmContainer>
        <ButtonContainer>
          <PrimaryButton
            size="large"
            label={t('checkin.start_checkin')}
            onClick={() => {
              startCheckin();
            }}
          />
        </ButtonContainer>
      </CenterContainer>
    </DialogDefault>
  );
};
