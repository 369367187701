import { styled, Button as MaterialButton } from '@material-ui/core';

export const Container: any = styled('div')({
  display: 'flex',
});

export const ContainerImg: any = styled('div')({
  width: 250,
  height: 250,
  borderRadius: '14px',
  flexShrink: 0,
});

export const Img: any = styled('img')({
  width: 250,
  height: 250,
  borderRadius: '14px',
  objectFit: 'fill',
});

export const Title: any = styled('p')({
  fontFamily: 'Barlow, sans-serif',
  fontWeight: 'bold',
  fontSize: '20px',
  marginBottom: '5px',
  color: '#000000',

  '@media screen and (max-width: 600px)': {
    fontSize: '16px',
    fontFamily: 'IBM Plex Sans',
    fontWeight: 'normal',
    margin: 0,
  },
});

export const Description: any = styled('p')({
  fontFamily: 'Barlow, sans-serif',
  fontWeight: 500,
  fontSize: '15px',
  color: '#000000',
});

export const ContainerText: any = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 30px',
  minWidth: '250px',
  maxWidth: '250px',
  overflow: 'hidden',
  justifyContent: 'space-between',
});

export const Button: any = styled(MaterialButton)({
  borderRadius: 25,
  height: 50,
  alignSelf: 'center',
  minWidth: 170,
  fontFamily: 'Barlow, sans-serif',

  '@media screen and (max-width: 600px)': {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 0,
    fontSize: '0.9rem',
  },
});

export const ContainerProgress: any = styled('div')({
  borderRadius: '50%',
  position: 'absolute',
  bottom: -290,
  right: -270,

  '@media screen and (max-width: 600px)': {
    bottom: '-57vw',
    right: -10,
  },
});

export const ContainerMobile: any = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '20px 0',
  borderRadius: '14px',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
});

export const ImgMobile: any = styled('img')({
  borderRadius: '14px 14px 0 0',
  width: '100%',
  height: '50vw',
  objectFit: 'fill',
});

export const ContainerDescriptionMobile: any = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px 20px 40px 20px',
  minHeight: '180px',
});
