import React, { useCallback, useEffect, useState } from 'react';
import { NextPage, GetStaticProps } from 'next';

import { Head } from 'components/core';

import { Box, Typography } from '@material-ui/core';

import { useUser } from '@hooks/user';
import { useFetch } from '@hooks/fetch';

import { getMaxText } from '@utils/format';
import { IResponse } from '@utils/interfaces/response';
import { IStudentEnrollment } from '@utils/interfaces/studentEnrollment';
import { IStudentCourse } from '@utils/interfaces/studentCourses';

import Footer from '@components/core/Footer';
import { Link } from '@components/ui';

import { HeaderContainer, Button, FeedbackCard } from '@styles/home/styles';
import dayjs from 'dayjs';
import { useAuth } from '@hooks/auth';

import ContentCourse from '@components/core/ContentCourse';

import { ICourseItem } from '@utils/interfaces/course';

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { PopupOnboarding } from '@components/core/PopupOnboarding';
import { PopupPresence } from '@components/core/PopupPresence';
import Cookies from 'universal-cookie';
import { useGa } from '@hooks/ga';
import { ABC_SHOWCASE_NAME_TEST } from '@services/abTest/constants';

interface IStudentEnrollmentWithStudentCourses {
  studentEnrollment: IStudentEnrollment;
  studentCourse?: IStudentCourse;
}
const Home: NextPage = () => {
  const { t } = useTranslation('common');
  const { user } = useUser();
  const {
    setShowModalLogin,
    courseToAttendance,
    showModalConfirmPresence,
    setShowModalConfirmAttendance,
  } = useAuth();
  const [courseInProgress, setCourseInProgress] = useState<ICourseItem[]>([]);
  const [coursesToStart, setCoursesToStart] = useState<ICourseItem[]>([]);
  const { locale } = useRouter();
  const { data: studentEnrollments } = useFetch<IResponse<IStudentEnrollment>>(
    user?._id ? `/lms-student/student-enrollment?language=${locale}` : null,
  );
  const { data: studentCourses } = useFetch<IResponse<IStudentCourse>>(
    user?._id ? `/lms-student/student-course?language=${locale}` : null,
  );
  const ga = useGa();

  useEffect(() => {
    const cookies = new Cookies();
    const sendAbcTrack = cookies.get('sendAbcTrack');
    if (sendAbcTrack && sendAbcTrack === 'true') {
      const label = cookies.get(ABC_SHOWCASE_NAME_TEST);
      ga.sendEvent(ABC_SHOWCASE_NAME_TEST, 'load', label, true);
      cookies.remove('sendAbcTrack');
    }
  }, [ga]);

  useEffect(() => {
    if (studentEnrollments?.rows?.length && studentCourses?.rows) {
      const { toStart, inProgress } = handleCourses(
        studentEnrollments.rows,
        studentCourses.rows,
      );
      setCoursesToStart(toStart);
      setCourseInProgress(inProgress);
    }
  }, [studentEnrollments, studentCourses]);

  const canAccess = useCallback(() => {
    const index = studentEnrollments?.rows?.findIndex(
      enrollment => enrollment?.course?._id,
    );
    return index !== -1;
  }, [studentEnrollments]);
  const ContentBox: any = Box;
  return (
    <>
      <Head>
        <title>StartSe</title>
      </Head>
      <ContentBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100vh"
        style={{ background: '#F6F7F8' }}
      >
        <div>
          <HeaderContainer>
            <Typography variant="h1" color="textPrimary">
              {t('home_welcome_message')}
              {user?.firstName ? `, ${user?.firstName}` : ''}!
            </Typography>
            <Typography variant="h3" color="textSecondary">
              {t('home_subtitle_message')}
            </Typography>
          </HeaderContainer>
          {!user?._id ? (
            <ContentBox m="auto" maxWidth="452px">
              <FeedbackCard>
                <ContentBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  textAlign="center"
                >
                  <Typography variant="h3" color="textSecondary">
                    <strong>{t('home_unauthenticated_title')}</strong>
                  </Typography>
                  <ContentBox mt={2}>
                    <Typography variant="h3" color="textSecondary">
                      {t('home_unauthenticated_message')}
                    </Typography>
                  </ContentBox>
                  <ContentBox mt={4}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setShowModalLogin(true)}
                    >
                      {t('login_button')}
                    </Button>
                  </ContentBox>
                </ContentBox>
              </FeedbackCard>
            </ContentBox>
          ) : (
            <>
              {!canAccess() ? (
                <ContentBox m="auto" maxWidth="452px">
                  <FeedbackCard>
                    <ContentBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      width="100%"
                      textAlign="center"
                    >
                      <Typography variant="h3" color="textSecondary">
                        <strong>{t('home_not_subscribe_title')}</strong>
                      </Typography>
                      <ContentBox mt={2}>
                        <Typography variant="h3" color="textSecondary">
                          {t('know_university_courses')}
                        </Typography>
                      </ContentBox>
                      <ContentBox mt={4}>
                        <Link
                          href={process.env.NEXT_PUBLIC_SHOWCASE}
                          noDecoration
                        >
                          <Button color="primary" variant="contained">
                            {t('know_button')}
                          </Button>
                        </Link>
                      </ContentBox>
                    </ContentBox>
                  </FeedbackCard>
                </ContentBox>
              ) : (
                <>
                  {courseInProgress?.length > 0 && (
                    <ContentCourse
                      showProgress
                      title={t('home_ongoing_courses')}
                      courses={courseInProgress}
                    />
                  )}
                  {coursesToStart?.length > 0 && (
                    <>
                      <ContentCourse
                        data-testid="start-course-lms"
                        title={t('home_to_start_courses')}
                        textButton={t('start_button')}
                        courses={coursesToStart}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div>
          <Footer />
        </div>
      </ContentBox>
      <PopupOnboarding />
      <PopupPresence
        open={showModalConfirmPresence}
        onClickCloseButton={() =>
          setShowModalConfirmAttendance(!showModalConfirmPresence)
        }
        courseName={courseToAttendance.course}
      />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default Home;

function formatToCourseList({
  studentEnrollment,
  studentCourse,
}: IStudentEnrollmentWithStudentCourses): ICourseItem {
  return {
    name: getMaxText(studentEnrollment?.course?.name),
    subtitle: getMaxText(studentEnrollment?.course?.subtitle),
    description: getMaxText(studentEnrollment?.course?.description),
    bannerImageUrl: studentEnrollment?.course?.bannerImageUrl,
    cardImageUrl: studentEnrollment?.course?.cardImageUrl,
    slug: studentEnrollment?.course?.slug,
    id: studentEnrollment?.course?.id,
    lastAccessLessonSlug: studentCourse?.lastAccessLesson?.slug,
    isExpired: dayjs(studentEnrollment?.expiresAt).isBefore(dayjs()),
    progress: studentCourse?.progress ?? 0,
    sections: studentEnrollment?.course?.sections,
  };
}

function handleCourses(
  studentEnrollments: IStudentEnrollment[],
  studentCourses: IStudentCourse[],
) {
  const merged: IStudentEnrollmentWithStudentCourses[] = studentEnrollments.map(
    studentEnrollment => ({
      studentEnrollment,
      studentCourse: studentCourses.find(
        studentCourse =>
          studentCourse?.course?.id === studentEnrollment?.course?.id,
      ),
    }),
  );
  // filtra apenas quem possui student course
  const inProgress = merged
    .filter(
      ({ studentCourse, studentEnrollment }) =>
        !!studentCourse && studentEnrollment?.course?._id,
    )
    .map(m => formatToCourseList(m));

  // filtra apenas quem NÃO possui student course
  const toStart = merged
    .filter(
      ({ studentCourse, studentEnrollment }) =>
        !studentCourse && studentEnrollment?.course?._id,
    )
    .map(m => formatToCourseList(m));

  return { toStart, inProgress };
}
