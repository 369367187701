import { Box, styled } from '@material-ui/core';

export const CenterContainer: any = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const CourseAlmostStarting: any = styled(Box)(({ theme }) => ({
  fontSize: '1.72em',
  fontWeight: 400,
  fontFamily: 'Barlow',
  marginTop: '0.3em',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1em',
  },
}));

export const CourseName: any = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Barlow',
  fontSize: '1.72em',
  textTransform: 'uppercase',
  marginTop: '18px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3em',
  },
}));

export const NeedToConfirmContainer: any = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const NeedToConfirm: any = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontFamily: 'Barlow',
  fontSize: '1.3em',
  marginTop: '18px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.95em',
  },
}));

export const ShallWe: any = styled(Box)({
  fontWeight: 400,
  fontFamily: 'Barlow',
  fontSize: '0.95em',
  textAlign: 'center',
});

export const ButtonContainer: any = styled(Box)(({ theme }) => ({
  maxWidth: '12em',
  width: '100%',
  marginTop: '24px',
  '& a': {
    textDecoration: 'none',
  },
  '& button': {
    borderRadius: '0.625em',
    height: 44,
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
    '& span': {
      marginLeft: '-2px',
      marginRight: '2px',
    },
    '& p': {
      fontWeight: 600,
      fontFamily: 'Barlow',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.750em',
      },
    },
    '& .MuiSvgIcon-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.981em',
      },
    },
  },
}));

export const CalendarContainer: any = styled(Box)(({ theme }) => ({
  '& svg': {
    width: '3.75em',
    height: '3.75em',
    [theme.breakpoints.down('sm')]: {
      width: '2.5em',
      height: '2.5em',
    },
  },
}));
