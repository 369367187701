import { ICourseItem } from '@utils/interfaces/course';
import { Link } from '@components/ui';
import { CircularProgress } from '@components/core';
import { useTracking } from '@hooks/tracking';
import { Box } from '@material-ui/core';

import {
  Container,
  ContainerImg,
  Img,
  ContainerText,
  Description,
  Title,
  Button,
  ContainerProgress,
} from './styles';

interface CardDesktopProps {
  course: ICourseItem;
  textButton?: string;
  showProgress?: boolean;
}

const CardDesktop: React.FC<CardDesktopProps> = ({
  course,
  textButton,
  showProgress,
}) => {
  const { track } = useTracking();
  const BoxContent: any = Box;
  return (
    <>
      {showProgress && (
        <BoxContent position="relative">
          <ContainerProgress>
            <CircularProgress value={course.progress} showLabel />
          </ContainerProgress>
        </BoxContent>
      )}
      <Container>
        <ContainerImg>
          <Img src={course.cardImageUrl} alt={course.name} />
        </ContainerImg>

        <ContainerText>
          <BoxContent>
            <Title>{course.name}</Title>
            <Description>{course.description}</Description>
          </BoxContent>
          <Link
            href={`/curso/${course.slug}`}
            noDecoration
            disabled={course.isExpired}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={course.isExpired}
              onClick={() => {
                track('Start course', {
                  courseSlug: course?.slug,
                  courseTitle: course?.name,
                });
              }}
            >
              {course.isExpired ? 'Curso Expirado' : textButton || 'Continuar'}
            </Button>
          </Link>
        </ContainerText>
      </Container>
    </>
  );
};

export default CardDesktop;
