import { ICourseItem } from '@utils/interfaces/course';
import { Box } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useTracking } from '@hooks/tracking';
import {
  Container,
  ContentDesktop,
  Title,
  ContainerTitle,
  ContentMobile,
} from './styles';

import CardDesktop from './Card/Desktop';
import CardMobile from './Card/Mobile';

import ArrowControl from './ArrowControl';

interface ContentCourseProps {
  title: string;
  textButton?: string;
  courses: ICourseItem[];
  showProgress?: boolean;
}

const ContentCourse: React.FC<ContentCourseProps> = ({
  courses,
  title,
  textButton,
  showProgress,
}) => {
  const refContent = useRef<HTMLDivElement>(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const { track } = useTracking();
  const [maxScroll, setMaxScroll] = useState(0);
  const ContentBox: any = Box;
  const handleScroll = (event: 'next' | 'prev') => {
    if (refContent.current) {
      const { scrollLeft, clientWidth } = refContent.current;
      const valueScroll = event === 'next' ? clientWidth : -clientWidth;

      setCurrentScroll(scrollLeft + valueScroll);
      refContent.current.scrollTo({
        left: scrollLeft + valueScroll,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (refContent.current) {
      const { scrollWidth, clientWidth } = refContent.current;
      setMaxScroll(scrollWidth - clientWidth);
    }
  }, [refContent.current?.clientWidth]);

  return (
    <Container>
      <ContainerTitle>
        <Title>{title}</Title>
        <ArrowControl
          currentScroll={currentScroll}
          maxScroll={maxScroll}
          onPressNext={() => {
            handleScroll('next');
            track('Next previous course', {});
          }}
          onPressPrev={() => handleScroll('prev')}
        />
      </ContainerTitle>
      <ContentDesktop
        innerRef={refContent}
        vertical
        hideScrollbars
        onScroll={scrollLeft => setCurrentScroll(scrollLeft)}
      >
        {courses?.map((course, index) => (
          <CardDesktop
            key={index.toString()}
            course={course}
            textButton={textButton}
            showProgress={showProgress}
          />
        ))}
        <ContentBox minWidth="10px" height="1px" />
      </ContentDesktop>

      <ContentMobile>
        {courses?.map((course, index) => (
          <CardMobile
            key={index.toString()}
            course={course}
            textButton={textButton}
            showProgress={showProgress}
          />
        ))}
      </ContentMobile>
    </Container>
  );
};

export default ContentCourse;
