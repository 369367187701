/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IContent, IContentList } from '@utils/interfaces/Content';
import { ICourseLesson } from '@utils/interfaces/courseLesson';
import dayjs from 'dayjs';
import { orderBy, chain } from 'lodash';

export const getMaxText = (text: string, max = 100): string => {
  if (text) {
    return text.substring(0, max).trim() + (text.length <= max ? '' : '...');
  }
  return '';
};
/* eslint-disable @typescript-eslint/no-explicit-any */
export const arrayToStringWithComma = (array: any[]) =>
  array.join().split(',').join(', ');

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function niceBytes(x): string {
  let l = 0;
  let n = parseInt(x, 10) || 0;

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export function formatToModuleWithCourseLessons(
  courseLessons: ICourseLesson[],
) {
  return chain(courseLessons)
    .groupBy(x => x?.courseModule.id)
    .filter(courseLesson => Boolean(courseLesson[0]?.courseModule))
    .map(courseLesson => {
      const { id, title, position } = courseLesson[0]?.courseModule;
      return {
        id,
        title,
        position,
        courseLessons: orderBy(courseLesson, ['position'], ['asc']),
      };
    })
    .orderBy('position', 'asc')
    .value();
}

export const formatExternalLink = (link: string, params: any = {}) => {
  let linkFormated = link && link.indexOf('?') > -1 ? `${link}&` : `${link}?`;
  linkFormated += Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');

  return linkFormated;
};

export const convertHourStringForMinutesNumber = (hour: string) => {
  return Number(hour.substr(0, 2)) * 60 + Number(hour.substr(3, 2));
};

export const convertMinutesNumberForHourString = (minutes: number) => {
  const hour = `00${Math.trunc(minutes / 60)}`.slice(-2);
  const min = `00${minutes % 60}`.slice(-2);
  return `${hour}:${min}`;
};

export const getDateStringByMilliseconds = (
  durationInMilliseconds: number,
): string => {
  const date = dayjs()
    .startOf('day')
    .add(durationInMilliseconds, 'millisecond');

  const hh = date.hour() || 0;
  const mm = date.minute() || 0;
  const ss = date.second() || 0;

  let strDate = '--';
  if (hh) {
    strDate = mm ? `${hh}h ${mm}min` : `${hh}h`;
  } else if (mm) {
    strDate = ss ? `${mm}min ${ss}s` : `${mm}min`;
  } else if (ss) {
    strDate = `${ss}s`;
  }
  return strDate;
};

export const formatContent = (contents: IContent[]): IContentList[] => {
  return (contents || []).reduce((acc, cur) => {
    const videos = cur?.videos.map(video => ({
      title: video?.title || '',
      thumbnail: video?.thumbnailUrl || null,
      background: video?.thumbnailUrl || null,
      url: `/aulas/${cur?.slug}/${video?.slug}`,
      time: getDateStringByMilliseconds(video?.videoTime),
      type: 'video',
      category: null,
      rating: video?.rating || null,
      id: video?.id,
    }));

    const articles = cur?.articles.map(article => ({
      title: article?.title,
      thumbnail: article?.feature_image?.formats?.thumbnail?.url || null,
      background:
        article?.feature_image?.formats?.small?.url ||
        article?.feature_image?.formats?.medium?.url ||
        article?.feature_image?.formats?.large?.url ||
        article?.feature_image?.url ||
        null,
      url: `/artigos/${article?.slug}`,
      time: `${article?.read_time || 10} min`,
      type: 'article',
      categories: article?.categories || [],
      id: article?.id,
      rating: article?.rating || null,
    }));

    const max =
      articles.length > videos.length ? articles.length : videos.length;
    const concat = [];
    for (let i = 0; i < max; i += 1) {
      if (articles.length > i) concat.push(articles[i]);
      if (videos.length > i) concat.push(videos[i]);
    }

    acc.push({
      name: cur.name,
      slug: cur.slug,
      stories: [...concat],
    });

    return acc;
  }, []);
};
