export enum SCROLL_CONTENT {
  BODY = 'body',
  PAPER = 'paper',
}

export enum DIALOG_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
