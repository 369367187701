import { Box, styled } from '@material-ui/core';

export const CenterContainer: any = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const Title: any = styled(Box)(({ theme }) => ({
  fontSize: '29px',
  fontWeight: 500,
  fontFamily: 'Barlow',
  color: '#0080ED',
  marginTop: '9px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.9em',
  },
}));

export const CourseName: any = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Barlow',
  fontSize: '42px',
  marginBottom: '10px',

  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.1em',
  },
}));

export const NeedToConfirmContainer: any = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const NeedToConfirm: any = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontFamily: 'Barlow',
  fontSize: '1.4em',
  // marginTop: '10px',
  color: '#333333',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.0em',
  },
}));

export const ButtonContainer: any = styled(Box)(({ theme }) => ({
  maxWidth: '18em',
  width: '100%',
  marginTop: '24px',
  '& a': {
    textDecoration: 'none',
  },
  '& button': {
    borderRadius: '0.625em',
    height: 44,
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
    '& span': {
      marginLeft: '-2px',
      marginRight: '2px',
    },
    '& p': {
      fontWeight: 600,
      fontFamily: 'Barlow',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.750em',
      },
    },
    '& .MuiSvgIcon-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.981em',
      },
    },
  },
}));
