import { PrimaryButton } from '@startse/design-system';
import { Link } from '@components/ui';

import { DIALOG_SIZE } from '@utils/constants/dialog';
import { useTranslation } from 'next-i18next';
import { DialogDefault } from '@components/core/DialogDefault';

import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAuth } from '@hooks/auth';
import { useTracking } from '@hooks/tracking';
import {
  CenterContainer,
  Title,
  CourseName,
  NeedToConfirmContainer,
  NeedToConfirm,
  ButtonContainer,
} from './styles';

export const PopupOnboarding: React.FC = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const [open, setOpen] = useState(true);
  const { showModalCompleted, setShowModalCompleted } = useAuth();
  const { track } = useTracking();

  return (
    <>
      {showModalCompleted && (
        <DialogDefault
          fullWidth
          open={open}
          onClickCloseButton={() => {
            setOpen(false);
            setShowModalCompleted(false);
            track('Close modal onboarding', {});
          }}
          maxWidth={DIALOG_SIZE.SM}
        >
          <CenterContainer>
            <CourseName>{`${t('onboarding.course')}`}</CourseName>
            <NeedToConfirmContainer>
              <NeedToConfirm>{t('onboarding.help')}</NeedToConfirm>
            </NeedToConfirmContainer>
            <NeedToConfirmContainer>
              <NeedToConfirm>{t('onboarding.complete')}</NeedToConfirm>
            </NeedToConfirmContainer>
            <Title>{`${t('onboarding.startup')}`}</Title>
            <ButtonContainer data-testid="complete-register-onboarding-div">
              <Link
                href={{
                  pathname: `${process.env.NEXT_PUBLIC_ONBOARDING}/${locale}`,
                  query: { redirect: window.location.href },
                }}
              >
                <PrimaryButton
                  size="large"
                  label={t('onboarding.register')}
                  data-testid="complete-register-onboarding-button"
                />
              </Link>
            </ButtonContainer>
          </CenterContainer>
        </DialogDefault>
      )}
    </>
  );
};
